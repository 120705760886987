import React from "react"
import { Layout, Seo, HomePageContent } from "../components"

const IndexPage = () => {
  return (
    <Layout>
      {/* <Seo title="Statistics - Home" /> */}
      <Seo title="DR Congo Developer Survey 2021" />
      <HomePageContent />
    </Layout>
  )
}

export default IndexPage
